export enum CreateActividadParroquialEnum {
    CATEDRAL_DE_LA_PLATA = "Catedral de La Plata",
    AGENDAR_TURNOS = "Programar Encuentro",
    ADMINISTRACION_DEL_GRUPO = "Administración del Grupo",
    ALTA_DE_ACTIVIDAD_PARROQUIAL = "Alta de Actividad Parroquial",
    LISTADO_DE_INTEGRANTES = "Listado de Integrantes",
    HORARIOS_SECTOR = "Horarios / Sector",
    COORDINADORES = "Coordinadores",
    SELECCIONE_LA_ACTIVIDAD_PARROQUIAL = "Seleccione la Actividad Parroquial",
    SELECCIONE_GRUPO = "Seleccione grupo",
    SELECCIONE_TURNO = "Seleccione un turno",
    LIGA_DE_MADRES_Y_OTROS = "Liga de madres - Pastoral - Grupo de Oración - etc || o nuevo en el caso de no existir",
    ALCANCE_DE_LA_ACTIVIDAD = "Alcance de la actividad",
    PARROQUIAL = "Parroquial",
    DECANATO = "Decanato",
    ARQUIDIOCESANO = "Arquidocesano",
    COORDINADOR = "Coordinador",
    FIRST_NAME = "First Name",
    LAST_NAME = "Last Name",
    TIPO_DE_DOCUMENTO = "Tipo de documento",
    NUMERO_DE_DOCUMENTO = "Número de documento",
    FECHA_DE_NACIMIENTO = "Fecha de nacimiento",
    MAIL_DE_CONTACTO = "Mail de contacto",
    CELULAR_DE_CONTACTO = "Celular de contacto",
    ROL_DEL_COORDINADOR = "Rol del coordinador",
    TAREA = "Tarea",
    RESPONDE_A_UNA_LITURGIA = "Responde a un rito",
    Liturgia = "Liturgia",
    CELEBRANTE = "Celebrante",
    TE_DEUM = "Te Deum",
    GUARDAR = "GUARDAR",
    ACTIVIDAD_PARROQUIAL_CREADA = "Actividad parroquial creada correctamente.",
    ERROR_CREAR_ACTIVIDAD_PARROQUIAL = "No se pudo crear la actividad parroquial.",
    ACTIVIDAD_PARROQUIAL_ACTUALIZADA = "Actividad parroquial actualizada correctamente.",
    ERROR_ACTUALIZAR_ACTIVIDAD_PARROQUIAL = "No se pudo actualizar la actividad parroquial.",   
    AGREGAR_NUEVO_GRUPO = "Agregar nuevo grupo",
    AGREGAR_NUEVA_CATEGORIA = "Agregar nueva categoría",
    NOMBRE_NUEVO_GRUPO = "Nombre del nuevo grupo",
    AGREGAR = "AGREGAR",
    CONTAINED_BUTTON = "contained",
    PRIMARY = "primary",
    ADD_COORDINATOR = "Agregar Coordinador",
    REMOVE_COORDINATOR = "Eliminar Coordinador",
    ACTIVIDAD_PARROQUIAL_CARGADA_COORECTAMENTE = "Actividad parroquial cargada correctamente.",
    GRUPO_SE_AGREGARA_A_CATEGORIA_SELECCIONADA = "El grupo se agregará a la categoría seleccionada",
    SELECCIONE_ENCUENTRO = "Seleccione un encuentro",
}

export enum ValuesEnum {
    NAME_ID = "nameId",
    GROUP_ID = "groupId",
    TURNO_ID = "turnoId",
    SCOPE = "scope",
    COORDINATOR_FIRST_NAME = "coordinator.firstName",
    COORDINATOR_LAST_NAME = "coordinator.lastName",
    COORDINATOR_DOCUMENT_TYPE = "coordinator.documentType",
    COORDINATOR_DOCUMENT_NUMBER = "coordinator.documentNumber",
    COORDINATOR_BIRTH_DATE = "coordinator.birthDate",
    COORDINATOR_EMAIL = "coordinator.email",
    COORDINATOR_PHONE_NUMBER = "coordinator.phoneNumber",
    COORDINATOR_ROL = "coordinator.rol",
    COORDINATOR_RESPONSE_TO_RITE = "coordinator.responseToRite",
    NUMBER = "number",
    TEXT = "text",
}

export enum ValuesLabelEnum {
    NAME_ID = "Tipo de actividad parroquial",
    GROUP_ID = "Tipo de grupo de actividad parroquial",
    TURNO_ID = "Turno",
    SCOPE = "Alcance",
    COORDINATOR_FIRST_NAME = "Nombre del Coordinador",
    COORDINATOR_LAST_NAME = "Apellido del Coordinador",
    COORDINATOR_DOCUMENT_TYPE = "Tipo de Documento del Coordinador",
    COORDINATOR_DOCUMENT_NUMBER = "Número de Documento del Coordinador",
    COORDINATOR_BIRTH_DATE = "Fecha de Nacimiento del Coordinador",
    COORDINATOR_EMAIL = "Correo Electrónico del Coordinador",
    COORDINATOR_PHONE_NUMBER = "Número de Teléfono del Coordinador",
    COORDINATOR_ROL = "Rol del Coordinador",
    COORDINATOR_RESPONSE_TO_RITE = "Respuesta al Rito del Coordinador",
}

export const ActividadParroquialTextos = {
    TituloAdministracionGrupo: "Administración del Grupo",
    AltaActividad: "Alta de Actividad Parroquial",
    ProgramarEncuentro: "Programar Encuentro",
    ListadoEncuentros: "Listado de Encuentros",
    ListadoIntegrantes: "Listado de Integrantes",
    AltaParticipantes: "Alta de Participantes",
    HorariosSector: "Horarios | Sector",
    Coordinadores: "Coordinadores",
    CargaDeParticipante: "Carga de Participante",
    FechaBautismo: "Fecha de Bautismo",
};

export const ActividadParroquialRoutes = {
    AltaActividad: "/panel/actividad-parroquial/create",
    ListadoIntegrantes: "/panel/actividad-parroquial/listado-integrantes",
    AltaParticipantes: "/panel/actividad-parroquial/create-participantes",
    HorariosSector: "/panel/actividad-parroquial/horarios-sector",
    Coordinadores: "/panel/actividad-parroquial/coordinadores",
    ProgramarEncuentro: "/panel/actividad-parroquial/create-event",
    ListadoEncuentros: "/panel/actividad-parroquial/listado-encuentros",
};

export const AltaParticipanteTextos = {
    ESCUELA: "Escuela",
    ANIO_CURSANDO: "Que año esta cursando",
    CUE : "CUE",
    FECHA_BAUTISMO : "Fecha del bautismo",
    DATOS_NINIO : "Datos del niño",
    IGLESIA_BAUTISMO : "Iglesia de Bautismo",
    DATOS_PADRE : "Datos del Padre",
    DATOS_MADRE : "Datos de la Madre",
    DATOS_PADRINOS : "Datos del padrino/madrina",
    CATEDRAL_DE_LA_PLATA : "Catedral de La Plata",
    PARTICIPANTE_CREADO_CORRECTAMENTE : "Participante de actividad parroquial creado correctamente.",
    ERROR_CREAR_PARTICIPANTE : "Error al crear participante de actividad parroquial.",
}
