import { Grid } from "@mui/material";
import { CSSProperties } from "react";

export const stylesActividadParroquial: Record<string, CSSProperties> = {
    box1: {
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        height: "100%",
        gap: "2em",
    },
    box2: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "4.5%",
        paddingLeft: "5em",
    },
    box3: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",

    },
    h2: {
        color: "black",
        textAlign: "center"
    },
    divForm: {
        backgroundColor: "white",
        padding: "5%",
        width: "60%",
        height: "100%",
    },
    texfields: {
        backgroundColor: "white",
    },
    typography: {
        color: "#264B99",
        marginTop: "0.5em",
        fontSize: "1.1em",
    },
    typographyTipo: {
        color: "#264B99",
        marginBottom: "1em",
        fontSize: "1.1em",
    },
    typographyGroup: {
        color: "#264B99",
        marginTop: "1em",
        fontSize: "1.1em",
        marginBottom: "1em",
    },
    typographyRolAndRito: {
        color: "#264B99",
        marginTop: "0.5em",
        fontSize: "1.1em",
        marginBottom: "1em",
    },
    gridFormControl: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        paddingTop: "0.5em",
        gap: "1.5em",
    },
    formControlRadios: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "12em",
        width: "100%",
        alignItems: "center",
        paddingBottom: "1em",
    },
    staticDatePicker: {
        backgroundColor: "white",
        borderRadius: "20px",
        margin: "1em",
    },
    formRadioGroup: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "100%",
    },
    formRadioGroupItem: {
        flex: 1,
    },
    gridButton: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        marginTop: "4%",
    },
    buttonSave: {
        paddingLeft: "40px",
        paddingRight: "40px",
    },
    gridDates: {
        display: "flex",
        flexDirection: "row",
        gap: "2em",
    },
    stack: {
        paddingTop: "1em",
        paddingBottom: "1em",
    },
    avatars: {
        color: "#66666B",
        backgroundColor: "#F1F3F4",
        width: 24,
        height: 24,
        fontSize: 12,
        cursor: "pointer",
    },
    avatarsSelects: {
        backgroundColor: '#1976d2', color: '#fff', cursor: 'pointer'
    },
    boxAvatars: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: "2em",
        paddingTop: "1em",
        paddingBottom: "1em",
    },
    typographyStack: {
        color: "#66666B",
    },
    gridTipo: {
        paddingBottom: "1em",
    },
    gridHora: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "1em",
    },
    formControlSector: {
        width: "30%",
    },
    formControlSacerdote: {
        width: "30%",
    },
    imputsSectorSacerdote: {
        marginTop: '-4px',
        lineHeight: '1'
    },
    boxEnd: { 
        display: "flex", 
        gap: "1em",
        marginTop: "2em",
    },
    gridCupos: {
        width: "30%",
    },
    smallText: {
        fontSize: '0.75rem', 
    },
    boxRadios: {
        display: "flex",
        flexDirection: "column",
    },
    selectTipo: {
        borderRadius: "5px",
    },
    semanaTipo: {
        borderRadius: "5px",
        width: "10%",
    },
    fechaTipo: {
        borderRadius: "5px",
        width: "10%",
    },
    sectorTipo: { 
        maxHeight: '2.5em' 
    },
    sacerdoteTipo: { 
        maxHeight: '2.5em' 
    },
    containerLinks: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        marginTop: '16px',
    },
    title: {
        borderBottom: '1px solid black',
        marginBottom: '20px',

    },
    link: {
        display: 'block',
        marginTop: '8px',
        textDecoration: 'none',
        color: 'black',
    },
    italicLink: {
        display: 'block',
        marginTop: '8px',
        textDecoration: 'none',
        color: 'grey',
        fontStyle: 'italic',
        paddingBottom: '8px',
    },
    activeLink: {
        color: '#264B99',
    },
    addingNewCategory: { 
        marginTop: "1em", 
        display: "flex", 
        gap: "1em" 
    },
    addingNewGroup: {
        marginTop: "1em",
        display: "flex",
        gap: "1em",
    },
    GridAddNewCategory: {
        marginRight: "-10px" ,
    },
    gridAddNewGroup: {
        marginRight: "-10px" ,
    },
    buttonAddNewCategory: {
        margin: "0", 
        minWidth: "80px" ,
    },
    buttonAddNewGroup: {
        margin: "0", 
        minWidth: "80px" ,
    },
    gridCoordinator: {
        display: "flex", 
        justifyContent: "space-between" 
    },
    buttonAgendarTurnos: {
      marginLeft: "1em",
      position: "relative",
    },
        
}
