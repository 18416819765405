import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, FormControl, RadioGroup, Radio, FormControlLabel, Select, MenuItem, TextField, Checkbox, Button } from '@mui/material';
import { ActividadParroquialTextos, AltaParticipanteTextos, CreateActividadParroquialEnum, ValuesEnum, ValuesLabelEnum } from '../../../enums/pages/CreateActividadParroquialEnum';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataPersonal, DATE, DatosPersonales, DOCUMENT, Errors, FormTextSend, IGLESIA, IGLESIA_CAPITALIZED, Locaciones, Locations, Sizes, State, TEXT, TIPO } from '../../../enums/components/componentsEnum';
import { inputLabelStyles, PeopleActividadParroquialStyles } from './PeopleActividadParroquialStyles';
import DocumentTypeComponent from '../../../components/autocomplete/documentType/DocumentTypeComponent';
import { getDocumentTypes } from '../../../utils/forms/formsUtils';
import { LoadState } from '../../../enums/LoadStateEnum';
import peopleServie from "../../../services/people";
import { assignResponseToParticipante, initialParticipanteState, optionsYears, sortByProperty } from '../../../utils/actividadParroquialUtils/UtilsActividadParroquial';
import LocationForm from '../../../components/forms/genericLocationForm/LocationForm';
import LocationSelect from '../../../components/autocomplete/countries/LocationSelect';
import { useQuery } from 'react-query';
import { locacionesService } from '../../../services/locaciones';
import { stylesActividadParroquial } from '../createActividadParroquial/CreateActividadParroquialStyles';
import { ActividadParroquialNombre, AllKeys, BirthPlaces, Group, IglesiaAddress, IglesiaDirection, NestedKeys, Participante, SchoolType, TopLevelKeys } from '../InterfacesActividadParroquial';
import LinksAndHeader from './LinksAndHeader';
import colegioService from '../../../services/colegios/colegioService';
import actividadParroquialService from '../../../services/actividadParroquial/ActividadParroquialService';
import { stylesCreateTurno } from '../../turnosV2/Createturno/stylesCreateTurno';
import turnoExpecificationService from '../../../services/turnosExpecification';
import grupoParroquialService from '../../../services/grupoParroquial/GrupoParroquialService';
import participanteActividadParroquialService from '../../../services/ParticipanteActividadParroquial/ParticipanteActividadParroquialService';
import { queryClient } from '../../../App';
import { addNotification } from '../../../utils/notifications';
import { on } from 'events';
import { set } from 'date-fns';
import sacramentosService from '../../../services/sacramentos';

const CreateParticipante: React.FC = () => {
  const navigate = useNavigate();
  const [forceRender, setForceRender] = useState<Date>();
  const [isFetch, setIsFetch] = useState(false);
  const loadState = useRef(LoadState.DEFAULT);
  const [iglesias, setIglesias] = useState<any[]>([]);
  const [iglesiaProvinces, setIglesiaProvinces] = useState<any[]>([]);
  const [iglesiaParts, setIglesiaParts] = useState<any[]>([]);
  const [birthNinioPlaces, setBirthNinioPlaces] = useState<BirthPlaces>({
    nationality: null,
    birthProvince: null,
    birthPart: null,
    birthLocality: null,
  });
  const [addressIglesia, setAddressIglesia] = useState<IglesiaAddress>({
    country: null,
    province: null,
    part: null,
    locality: null,
  });
  let [participante, setParticipante] = useState<Participante>(initialParticipanteState());
  const [iglesiaDirection, setIglesiaDirection] = useState<IglesiaDirection>({
    iglesia: null,
    part: null,
    province: null,
    country: null,
  });
  const [colegios, setColegios] = useState<SchoolType[]>([]);
  const {
    data: countries,
    isLoading: locacionesDataIsLoading,
    error: locacionesDataError,
  } = useQuery<any | null>([Locaciones.LOCACIONES], () =>
    locacionesService.getCountries()
  );
  const [actividadParroquialNombres, setActividadParroquialNombre] = useState<ActividadParroquialNombre[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [turnosExpecification, setTurnosExpecification] = useState([]);
  const [fetchedSchool, setFetchedSchool] = useState<SchoolType | null>(null);
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const [disabledNinio, setDisabledNinio] = useState(false);
  const [disabledFather, setDisabledFather] = useState(false);
  const [disabledMother, setDisabledMother] = useState(false);
  const [disabledGodparent, setDisabledGodparent] = useState(false);
  const [disabledChurch, setDisabledChurch] = useState(false);
  const [disabledSchool, setDisabledSchool] = useState(false);
  const [disabledTurno, setDisabledTurno] = useState(false);
  const isEditRoute = pathParts.includes('edit-participantes');

  useEffect(() => {
    if (isEditRoute) {
      const document = pathParts[pathParts.length - 2];
      const documentType = pathParts[pathParts.length - 1];

      if (document && documentType) {
        handleFetchPerson(document, documentType, "ninio");
        handleItemChange(location.state?.ActividadParroquialNameId, 'nameId');
        setDisabledTurno(true);
        participanteActividadParroquialService.getParticipantesById(location.state?.id).then((response: any) => {
          onChangeAddressIglesia('nationality', response.colegio.localidad.part?.province.country);
          onChangeAddressIglesia('birthProvince', response.colegio.localidad.part?.province);
          onChangeAddressIglesia('birthPart', response.colegio.localidad.part);
          onChangeAddressIglesia('birthLocality', response.colegio.localidad);
          setFetchedSchool(response.colegio);
          setParticipante((prev) => ({
            ...prev,
            type: response.type,
            school: {
              ...prev.school,
              yearStudying: response.yearStudying,
              religious: response.religious,
              name: response.colegio.nombre,
              schoolId: response.colegio.id,
              cue: response.colegio.cue,
            }
          }));
          setDisabledSchool(true);
        }).catch((error: any) => {
          console.error('Error al traer participante', error);
        });
      }
    }
  }, [location]);

  useEffect(() => {
    if (participante.activity.nameId && isEditRoute) {
      handleItemChange(location.state?.groupParroquialId, 'groupId');
    }
  }, [participante.activity.nameId]);

  useEffect(() => {
    if (participante.activity.groupId && isEditRoute) {
      handleItemChange(location.state?.turnoId, 'turnoExpecificationId');
    }
  }, [participante.activity.groupId]);

  useEffect(() => {
    actividadParroquialService.getActividadNombres()
      .then((response: any) => {
        const sortedResponse = sortByProperty(response, 'name');
        setActividadParroquialNombre(sortedResponse);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const resetStateValidator = (
    state: LoadState = LoadState.DEFAULT,
    isFetch = false,
  ) => {
    setIsFetch(isFetch);
    loadState.current = state;
    setForceRender(new Date());
  };

  const handleFetchPerson = async (dni: string, documentType: string, typePeople: any) => {
    dni = dni ?? "";
    documentType = documentType ?? "";
    if (!!dni && dni.length > 0 && documentType.length > 0) {
      peopleServie
        .findPeople(dni, documentType)
        .then((response: any) => {
          if (!!response) {
            let updatedParticipante: Participante;
            let bautismoSacramento: any;
            switch (typePeople) {
              case "ninio":
                bautismoSacramento = response.sacramentos?.find((sacramento: any) => sacramento.sacramento.type === "Bautismo");
                updatedParticipante = assignResponseToParticipante(participante, response, typePeople, bautismoSacramento);
                let updatedNinio = updatedParticipante?.ninio;
                if (bautismoSacramento) {
                  updatedNinio = {
                    ...updatedParticipante?.ninio,
                    dateSacramento: new Date(bautismoSacramento?.sacramento?.startDate).toISOString().split('T')[0],
                  }
                  sacramentosService.getGodfatherBySacramentoId(bautismoSacramento.sacramento.id).then((response: any) => {

                    setParticipante((prev) => ({
                      ...prev,
                      godparent: {
                        name: response.name,
                        surname: response.surname,
                        document_type: response.document_type,
                        document: response.document,
                        birthdate: response.birthdate,
                        email: response.email,
                        phone: response.phone,
                      }
                    }));
                    setDisabledChurch(true);
                    response.document !== "" && setDisabledGodparent(true);
                  }).catch((error: any) => {
                    console.error('Error al traer padrino', error);
                  });
                }
                setBirthNinioPlaces({
                  nationality: response?.locality?.part?.province?.country,
                  birthProvince: response?.locality?.part?.province,
                  birthPart: response?.locality?.part,
                  birthLocality: response?.locality,
                });

                setIglesiaDirection({
                  iglesia: bautismoSacramento?.sacramento?.iglesia,
                  part: bautismoSacramento?.sacramento?.iglesia?.locality?.part,
                  province: bautismoSacramento?.sacramento?.iglesia?.locality?.part?.province,
                  country: bautismoSacramento?.sacramento?.iglesia?.locality?.part?.province?.country,
                });

                setParticipante((prev) => ({
                  ...prev,
                  iglesia: {
                    ...prev.iglesia,
                    book: bautismoSacramento?.sacramento?.book,
                    folio: bautismoSacramento?.sacramento?.folio,
                  },
                  ninio: updatedNinio,
                  father: updatedParticipante?.father,
                  mother: updatedParticipante?.mother,
                }));
                setDisabledNinio(true);
                console.log('updatedParticipante', updatedParticipante.godparent.document);

                updatedParticipante.father.document !== "" && setDisabledFather(true);
                updatedParticipante.mother.document !== "" && setDisabledMother(true);

                break;
              case "father":
                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                setParticipante((prev) => ({
                  ...prev,
                  father: updatedParticipante.father,
                }));
                setDisabledFather(true);
                break;
              case "mother":
                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                setParticipante((prev) => ({
                  ...prev,
                  mother: updatedParticipante.mother,
                }));
                setDisabledMother(true);
                break;
              case "godparent":
                updatedParticipante = assignResponseToParticipante(participante, response, typePeople);
                setParticipante((prev) => ({
                  ...prev,
                  godparent: updatedParticipante.godparent,
                }));
                setDisabledGodparent(true);
                break;
              default:
                throw new Error(`Unknown typePeople: ${typePeople}`);
            }

            resetStateValidator(LoadState.LOADED, true);
          } else {
            resetStateValidator();
          }
        })
        .catch((error: any) => {
          resetStateValidator();
        });
    } else {
      resetStateValidator();
    }
  };

  const onChangeBirthNinioPlaces = (key: string, value: any) => {
    if (key === 'birthLocality') {
      setParticipante(prev => ({
        ...prev,
        ninio: {
          ...prev.ninio,
          birthLocality: value.id,
        }
      }));
    }
  };

  const onChangeAddressIglesia = (key: string, value: any) => {
    if (key === 'birthLocality') {
      setParticipante(prev => ({
        ...prev,
        school: {
          ...prev.school,
          localityId: value.id,
        }
      }));

      colegioService.findByLocalityId(value.id)
        .then((response: any) => {
          setColegios(response);
        })
        .catch((error: any) => {
          console.log(Errors.ERROR_GET_SCHOOLS, error);
        });
    }

    setAddressIglesia(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFormChange = (key: AllKeys, value: any) => {
    const keys = key.split('.') as [TopLevelKeys, NestedKeys?];
    const isDocumentType = keys[keys.length - 1] === 'document_type';

    if (keys.length === 1) {
      // Upload on top level
      setParticipante(prev => ({
        ...prev,
        [keys[0]]: isDocumentType ? value.id : value,
      }));
    } else if (keys.length === 2) {
      // Upload on nested level
      const [parentKey, childKey] = keys;
      setParticipante(prev => {
        const parentValue = prev[parentKey];
        if (typeof parentValue === 'object' && parentValue !== null) {
          return {
            ...prev,
            [parentKey]: {
              ...parentValue,
              [childKey!]: isDocumentType ? value.id : value,
            },
          };
        } else {
          return prev;
        }
      });
    }
  };

  const handleCountryChange = (key: any, value: any, type: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT
    }

    locacionesService.findProvincesByCountryId(value.id)
      .then((response: any) => {
        if (type === 'iglesia') {
          setIglesiaProvinces(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PROVINCES, error);
      })
  };

  const handleProvinceChange = (key: any, value: any, type: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT
    }

    locacionesService.findPartsByProvinceId(value.id)
      .then((response: any) => {
        if (type === 'iglesia') {
          setIglesiaParts(response);
        }
      })
      .catch((error: any) => {
        console.log(Errors.ERROR_GET_PARTS, error);
      })
  };

  const handlePartChange = (key: any, value: any, type: string) => {
    if (key == DOCUMENT) {
      loadState.current = LoadState.DEFAULT
    }
    locacionesService.findPartWithLocalities(value.id).then((response: any) => {

      setIglesias(response.localities.flatMap((locality: any) => {
        const localityName = locality.name;
        return locality.iglesias.map((iglesia: any) => {
          iglesia.name = `${iglesia.denomination}-(${localityName})`;
          return iglesia;
        });
      }))
    })
  };

  const handleItemChange = (value: any, type: "groupId" | "nameId" | 'turnoExpecificationId') => {
    if (type === "groupId") {
      setParticipante(prev => ({
        ...prev,
        activity: {
          ...prev.activity,
          groupId: value,
        }
      }));

      actividadParroquialService.findActividadByActivityNameIdAndGroupId(participante.activity.nameId, value).then((response: any) => {
        turnoExpecificationService.getTurnoEspecificationForActivityId(response.id).then((response: any) => {
          setTurnosExpecification(response);
        }
        ).catch((error: any) => {
          console.error('Error al traer turnos', error);
        });
      }).catch((error: any) => {
        console.error('Error al buscar actividad parroquial', error);
      });
    } else if (type === "nameId") {
      setParticipante(prev => ({
        ...prev,
        activity: {
          ...prev.activity,
          nameId: value,
        }
      }));

      grupoParroquialService.getGrupoWithOnlyIdAndName(value).then((response: any) => {
        const sortedResponse = sortByProperty(response, 'name');
        setGroups(sortedResponse);
      }).catch((error: any) => {
        console.error(error);
      });
    } else if (type === "turnoExpecificationId") {
      setParticipante(prev => ({
        ...prev,
        activity: {
          ...prev.activity,
          turnoExpecificationId: value,
        }
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const updatedNinio = {
      ...participante.ninio,
      locality: participante.ninio.birthLocality,
    };
    const participanteToSend = {
      type: participante.type,
      turnoExpecificationId: participante.activity.turnoExpecificationId,
      schoolId: participante.school.schoolId,
      yearStudying: participante.school.yearStudying,
      dateBaptism: participante.ninio.dateSacramento,
      book: participante.iglesia.book,
      folio: participante.iglesia.folio,
      idIgle: participante.iglesia.idIgle,
      religious: participante.school.religious,
      ninio: updatedNinio,
      father: participante.father,
      mother: participante.mother,
      godparent: participante.godparent,
    };
    participanteActividadParroquialService.createParticipante(participanteToSend)
      .then((response: any) => {
        addNotification(queryClient, "success", AltaParticipanteTextos.PARTICIPANTE_CREADO_CORRECTAMENTE);
      })
      .catch((error: any) => {
        console.error('Error al crear participante', error);
        addNotification(queryClient, "error", AltaParticipanteTextos.ERROR_CREAR_PARTICIPANTE);
      });
  }
  return (
    <Box sx={PeopleActividadParroquialStyles.box1}>
      <LinksAndHeader handleGoBack={handleGoBack} />
      <Box sx={PeopleActividadParroquialStyles.divForm}>
        <form onSubmit={handleSubmit}>


          <Grid container >
            <Typography style={stylesActividadParroquial.typographyTipo}>
              {CreateActividadParroquialEnum.SELECCIONE_LA_ACTIVIDAD_PARROQUIAL}
            </Typography>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    labelId={ValuesLabelEnum.NAME_ID}
                    id={ValuesEnum.NAME_ID}
                    value={participante.activity.nameId}
                    onChange={(e) => handleItemChange(e.target.value, 'nameId')}
                    fullWidth
                    size={Sizes.SMALL}
                    style={stylesActividadParroquial.selectTipo}
                    required
                    disabled={disabledTurno}
                  >
                    {actividadParroquialNombres.map((categoria) => (
                      <MenuItem key={categoria.id} value={categoria.id}>
                        {categoria.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Typography style={stylesActividadParroquial.typographyGroup}>
              {CreateActividadParroquialEnum.SELECCIONE_GRUPO}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    value={participante.activity.groupId}
                    onChange={(e) => handleItemChange(e.target.value, 'groupId')}
                    displayEmpty
                    sx={stylesActividadParroquial.formSelect}
                    fullWidth
                    size={Sizes.SMALL}
                    required
                    disabled={disabledTurno}
                  >
                    {groups?.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid style={PeopleActividadParroquialStyles.gridTipo} item xs={12}>
              <Typography style={stylesCreateTurno.typographyTipo}>
                {CreateActividadParroquialEnum.SELECCIONE_ENCUENTRO}
              </Typography>
              <Select
                labelId={ValuesLabelEnum.TURNO_ID}
                id={ValuesEnum.TURNO_ID}
                value={participante.activity.turnoExpecificationId || ""}
                fullWidth
                size={Sizes.SMALL}
                style={stylesCreateTurno.selectTipo}
                disabled={disabledTurno}
                required
                onChange={(e) => handleItemChange(e.target.value, 'turnoExpecificationId')}
              >
                {
                  turnosExpecification &&
                  turnosExpecification.map((turno: any) => {
                    //@ts-ignore
                    const diasHabilitados = (turno.enabledDays || []).map(dia => {
                      const diasMap = {
                        "L": "Lunes",
                        "M": "Martes",
                        "X": "Miércoles",
                        "J": "Jueves",
                        "V": "Viernes",
                        "S": "Sábado",
                        "D": "Domingo",
                      };
                      //@ts-ignore
                      return diasMap[dia];
                    }).join(", ");

                    const stringTurno = `Turno desde: ${new Date(turno.startTime).toLocaleDateString()} hasta: ${new Date(turno.endTimeRepetition).toLocaleDateString()} - Días habilitados: ${diasHabilitados} - Sector: ${turno.sector?.description}`;

                    return (
                      <MenuItem key={turno.id} value={turno.id}>
                        {stringTurno}
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </Grid>
          </Grid>

          <Typography variant="h5" style={PeopleActividadParroquialStyles.typographyCarga}>
            {ActividadParroquialTextos.CargaDeParticipante}
          </Typography>

          <Grid item xs={12} sx={PeopleActividadParroquialStyles.gridFormControl}>
            <FormControl component="fieldset" sx={PeopleActividadParroquialStyles.formControlRadios} disabled={disabledSchool}>
              <RadioGroup
                row
                name="type"
                value={participante.type}
                onChange={(e) => handleFormChange("type", e.target.value)}
                sx={PeopleActividadParroquialStyles.formRadioGroup}
              >
                <FormControlLabel required value="school" control={<Radio />} label="Colegio" sx={PeopleActividadParroquialStyles.formRadioGroupItem} />
                <FormControlLabel required value="Parroquia" control={<Radio />} label="Parroquia" sx={PeopleActividadParroquialStyles.formRadioGroupItem} />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sx={PeopleActividadParroquialStyles.gridFormControlChecked}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={participante.school.religious}
                  onChange={(e) => handleFormChange("school.religious", !participante.school.religious)}
                  name="religious"
                  disabled={disabledSchool}
                />
              }
              label="Religioso"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!participante.school.religious}
                  onChange={(e) => handleFormChange("school.religious", !participante.school.religious)}
                  disabled={disabledSchool}
                />
              }
              label="No religioso"
            />
          </Grid>

          <Grid container columnSpacing={2}>
            <LocationForm
              formData={addressIglesia}
              onChange={onChangeAddressIglesia}
              nacimiento={true}
              isFetch={isFetch}
              loadState={loadState}
              isInActivityParroquial={true}
              disabled={disabledSchool}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={PeopleActividadParroquialStyles.typographySchool}>
              {AltaParticipanteTextos.ESCUELA}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                value={participante.school.name}
                onChange={(e) => {
                  const selectedSchool = colegios.find(option => option.name === e.target.value);
                  const cue = selectedSchool ? selectedSchool.cue : '';
                  setParticipante(prev => ({
                    ...prev,
                    school: {
                      ...prev.school,
                      name: e.target.value,
                      schoolId: selectedSchool?.id ?? null,
                      cue: cue,
                    }
                  }));
                }}
                name="school"
                size={Sizes.SMALL}
                required
                disabled={disabledSchool}
              >
                {colegios &&
                  colegios.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid container columnSpacing={2}>
            <Grid item xs={6} >
              <Typography style={PeopleActividadParroquialStyles.typographySchool1}>
                {AltaParticipanteTextos.ANIO_CURSANDO}
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={participante.school.yearStudying}
                  onChange={(e) => handleFormChange("school.yearStudying", e.target.value)}
                  name="yearStudying"
                  size={Sizes.SMALL}
                  disabled={disabledSchool}
                >
                  {optionsYears &&
                    optionsYears.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography style={PeopleActividadParroquialStyles.typographySchool1}>
                {AltaParticipanteTextos.CUE}
              </Typography>
              <FormControl fullWidth>
                <TextField
                  value={participante.school.cue}
                  name="cue"
                  size={Sizes.SMALL}
                  disabled={true}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>



          <Grid container columnSpacing={2}>
            <Grid item xs={12} >
              <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
                {AltaParticipanteTextos.DATOS_NINIO}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.NAME}
                label={DatosPersonales.NAME}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={TEXT}
                disabled={disabledNinio}
                value={participante?.ninio.name ?? ""}
                onChange={(e) =>
                  handleFormChange("ninio.name", e.target.value)
                }
                style={{
                  borderRadius: "5px",
                }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.SURNAME}
                label={DatosPersonales.SURNAME}
                type={TEXT}
                InputLabelProps={{ sx: inputLabelStyles }}
                value={participante?.ninio.surname ?? ""}
                disabled={disabledNinio}
                onChange={(e) =>
                  handleFormChange("ninio.surname", e.target.value)
                }
                style={{
                  borderRadius: "5px",
                }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={2}>
              <DocumentTypeComponent
                id={DataPersonal.DOCUMENT_TYPE}
                InputLabelProps={{ sx: inputLabelStyles }}
                label={TIPO}
                disabled={disabledNinio}
                style={{
                  borderRadius: "5px",
                }}
                countries={getDocumentTypes()}
                onFocus={() => resetStateValidator()}
                onBlur={() =>
                  handleFetchPerson(participante.ninio.document, participante.ninio.document_type, "ninio")
                }
                value={participante.ninio.document_type ?? ""}
                onChange={(e: any, value: any) => {
                  setParticipante(prev => ({
                    ...prev,
                    ninio: {
                      ...prev.ninio,
                      document_type: value.id,
                    }
                  }));
                }}
                size={Sizes.SMALL}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id={DataPersonal.DNI}
                label={DatosPersonales.NUMBER_DOCUMENT}
                type={TEXT}
                disabled={disabledNinio}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{
                  borderRadius: "5px",
                }}
                value={participante.ninio.document}
                onBlur={(e) =>
                  handleFetchPerson(participante.ninio.document, participante.ninio.document_type, "ninio")
                }
                onChange={(e) =>
                  handleFormChange("ninio.document", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.BIRTHDATE}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                disabled={disabledNinio}
                value={participante.ninio.birthdate ?? ""}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{
                  borderRadius: "5px",
                }}
                onChange={(e) =>
                  handleFormChange("ninio.birthdate", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
              />
            </Grid>
            <LocationForm
              formData={birthNinioPlaces}
              onChange={onChangeBirthNinioPlaces}
              nacimiento={true}
              disabled={disabledNinio}
              isFetch={isFetch}
              loadState={loadState}
            />
          </Grid>

          {/* Iglesia's Data */}
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
                {AltaParticipanteTextos.IGLESIA_BAUTISMO}
              </Typography>
            </Grid>
            <Grid container columnSpacing={2} sx={PeopleActividadParroquialStyles.gridUbicacionIglesia}>
              <Grid item xs={6}>
                <LocationSelect
                  id={Locations.COUNTRY}
                  label={Locaciones.PAIS}
                  options={countries ?? []}
                  disabled={disabledChurch}
                  onChange={(key, value) => handleCountryChange(key, value, IGLESIA)}
                  size={Sizes.SMALL}
                  loadState={loadState}
                  errors={false}
                  value={iglesiaDirection.country}
                />
              </Grid>
              <Grid item xs={6}>
                <LocationSelect
                  id={Locations.PROVINCE}
                  label={Locaciones.PROVINCIA}
                  loadState={loadState}
                  options={iglesiaProvinces ?? []}
                  disabled={disabledChurch}
                  onChange={(key, value) => handleProvinceChange(key, value, IGLESIA)}
                  size={Sizes.SMALL}
                  errors={false}
                  value={iglesiaDirection.province}
                />
              </Grid>
              <Grid item xs={6}>
                <LocationSelect
                  id={Locations.PART}
                  label={Locaciones.PARTIDO}
                  options={iglesiaParts ?? []}
                  disabled={disabledChurch}
                  loadState={loadState}
                  onChange={(key, value) => handlePartChange(key, value, IGLESIA)}
                  size={Sizes.SMALL}
                  errors={false}
                  value={iglesiaDirection.part}
                />
              </Grid>
              <Grid item xs={6}>
                <LocationSelect
                  id={IGLESIA}
                  label={IGLESIA_CAPITALIZED}
                  options={iglesias ?? []}
                  disabled={disabledChurch}
                  loadState={loadState}
                  onChange={(key: any, value: any) => {
                    handleFormChange("iglesia.idIgle", value.id);
                  }}
                  size={Sizes.SMALL}
                  errors={false}
                  helperText={""}
                  value={iglesiaDirection.iglesia}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.LIBRO}
                  label={DataPersonal.LIBRO}
                  type={TEXT}
                  disabled={disabledChurch}
                  value={participante.iglesia.book ?? ""}
                  InputLabelProps={{ sx: inputLabelStyles }}
                  style={{
                    borderRadius: "5px",
                  }}
                  onChange={(e) =>
                    handleFormChange("iglesia.book", e.target.value)
                  }
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={DataPersonal.FOLIO}
                  label={DataPersonal.FOLIO}
                  type={TEXT}
                  disabled={disabledChurch}
                  value={participante.iglesia.folio ?? ""}
                  InputLabelProps={{ sx: inputLabelStyles }}
                  style={{
                    borderRadius: "5px",
                  }}
                  onChange={(e) =>
                    handleFormChange("iglesia.folio", e.target.value)
                  }
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id={DataPersonal.BIRTHDATE}
                  label={ActividadParroquialTextos.FechaBautismo}
                  type={DATE}
                  autoComplete={State.OFF}
                  value={participante.ninio.dateSacramento}
                  onChange={(e) => handleFormChange("ninio.dateSacramento", e.target.value)}
                  name="dateSacramento"
                  margin={Sizes.NORMAL}
                  size={Sizes.SMALL}
                  fullWidth
                  disabled={disabledChurch}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Father's Data */}
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
                {AltaParticipanteTextos.DATOS_PADRE}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.NAME}_father`}
                label={DatosPersonales.NAME}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={TEXT}
                disabled={disabledFather}
                value={participante?.father?.name ?? ""}
                onChange={(e) =>
                  handleFormChange("father.name", e.target.value)
                }
                style={{ borderRadius: "5px" }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.SURNAME}_father`}
                label={DatosPersonales.SURNAME}
                type={TEXT}
                InputLabelProps={{ sx: inputLabelStyles }}
                value={participante?.father?.surname ?? ""}
                disabled={disabledFather}
                onChange={(e) =>
                  handleFormChange("father.surname", e.target.value)
                }
                style={{ borderRadius: "5px" }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={2}>
              <DocumentTypeComponent
                id={`${DataPersonal.DOCUMENT_TYPE}_father`}
                InputLabelProps={{ sx: inputLabelStyles }}
                label={TIPO}
                disabled={disabledFather}
                style={{ borderRadius: "5px" }}
                countries={getDocumentTypes()}
                onFocus={() => resetStateValidator()}
                onBlur={() =>
                  handleFetchPerson(participante.father.document, participante.father.document_type, "father")
                }
                value={participante.father.document_type ?? ""}
                onChange={(e: any, value: any) => {
                  setParticipante(prev => ({
                    ...prev,
                    father: {
                      ...prev.father,
                      document_type: value.id,
                    }
                  }));
                }}
                size={Sizes.SMALL}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id={`${DataPersonal.DOCUMENT}_father`}
                label={DatosPersonales.NUMBER_DOCUMENT}
                type={TEXT}
                disabled={disabledFather}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{ borderRadius: "5px" }}
                value={participante.father.document ?? ""}
                onBlur={(e) =>
                  handleFetchPerson(participante.father.document, participante.father.document_type, "father")
                }
                onChange={(e) =>
                  handleFormChange("father.document", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.BIRTHDATE}_father`}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                disabled={disabledFather}
                value={participante.father.birthdate ?? ""}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{ borderRadius: "5px" }}
                onChange={(e) =>
                  handleFormChange("father.birthdate", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.EMAIL}
                label={DatosPersonales.EMAIL}
                type={TEXT}
                autoComplete={State.OFF}
                value={participante.father.email || ""}
                onChange={(e) => handleFormChange("father.email", e.target.value)}
                margin={Sizes.NORMAL}
                disabled={disabledFather}
                size={Sizes.SMALL}
                fullWidth
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.PHONE}
                label={DatosPersonales.TELEFONO}
                type={DataPersonal.NUMBER}
                autoComplete={State.OFF}
                value={participante.father.phone || ""}
                onChange={(e) => handleFormChange("father.phone", e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                disabled={disabledFather}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Mother's Data */}
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
                {AltaParticipanteTextos.DATOS_MADRE}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.NAME}_mother`}
                label={DatosPersonales.NAME}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={TEXT}
                disabled={disabledMother}
                value={participante?.mother?.name ?? ""}
                onChange={(e) =>
                  handleFormChange("mother.name", e.target.value)
                }
                style={{ borderRadius: "5px" }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.SURNAME}_mother`}
                label={DatosPersonales.SURNAME}
                type={TEXT}
                InputLabelProps={{ sx: inputLabelStyles }}
                value={participante?.mother?.surname ?? ""}
                disabled={disabledMother}
                onChange={(e) =>
                  handleFormChange("mother.surname", e.target.value)
                }
                style={{ borderRadius: "5px" }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={2}>
              <DocumentTypeComponent
                id={`${DataPersonal.DOCUMENT_TYPE}_mother`}
                InputLabelProps={{ sx: inputLabelStyles }}
                label={TIPO}
                disabled={disabledMother}
                style={{ borderRadius: "5px" }}
                countries={getDocumentTypes()}
                onFocus={() => resetStateValidator()}
                onBlur={() =>
                  handleFetchPerson(participante.mother.document, participante.mother.document_type, "mother")
                }
                value={participante.mother.document_type ?? ""}
                onChange={(e: any, value: any) => {
                  setParticipante(prev => ({
                    ...prev,
                    mother: {
                      ...prev.mother,
                      document_type: value.id,
                    }
                  }));
                }}
                size={Sizes.SMALL}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id={`${DataPersonal.DOCUMENT}_mother`}
                label={DatosPersonales.NUMBER_DOCUMENT}
                type={TEXT}
                disabled={disabledMother}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{ borderRadius: "5px" }}
                value={participante.mother.document ?? ""}
                onBlur={(e) =>
                  handleFetchPerson(participante.mother.document, participante.mother.document_type, "mother")
                }
                onChange={(e) =>
                  handleFormChange("mother.document", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.BIRTHDATE}_mother`}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                disabled={disabledMother}
                value={participante.mother.birthdate ?? ""}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{ borderRadius: "5px" }}
                onChange={(e) =>
                  handleFormChange("mother.birthdate", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.EMAIL}
                label={DatosPersonales.EMAIL}
                type={TEXT}
                autoComplete={State.OFF}
                value={participante.mother.email || ""}
                onChange={(e) => handleFormChange("mother.email", e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                disabled={disabledMother}
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.PHONE}
                label={DatosPersonales.TELEFONO}
                type={DataPersonal.NUMBER}
                autoComplete={State.OFF}
                value={participante.mother.phone || ""}
                onChange={(e) => handleFormChange("mother.phone", e.target.value)}
                margin={Sizes.NORMAL}
                disabled={disabledMother}
                size={Sizes.SMALL}
                fullWidth
              />
            </Grid>
          </Grid>

          {/* Godparent's Data */}
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography style={PeopleActividadParroquialStyles.typographyNinio}>
                {AltaParticipanteTextos.DATOS_PADRINOS}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.NAME}_godparent`}
                label={DatosPersonales.NAME}
                InputLabelProps={{ sx: inputLabelStyles }}
                type={TEXT}
                disabled={disabledGodparent}
                value={participante?.godparent?.name ?? ""}
                onChange={(e) =>
                  handleFormChange("godparent.name", e.target.value)
                }
                style={{ borderRadius: "5px" }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.SURNAME}_godparent`}
                label={DatosPersonales.SURNAME}
                type={TEXT}
                InputLabelProps={{ sx: inputLabelStyles }}
                value={participante?.godparent?.surname ?? ""}
                disabled={disabledGodparent}
                onChange={(e) =>
                  handleFormChange("godparent.surname", e.target.value)
                }
                style={{ borderRadius: "5px" }}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={2}>
              <DocumentTypeComponent
                id={`${DataPersonal.DOCUMENT_TYPE}_godparent`}
                InputLabelProps={{ sx: inputLabelStyles }}
                label={TIPO}
                disabled={disabledGodparent}
                style={{ borderRadius: "5px" }}
                countries={getDocumentTypes()}
                onFocus={() => resetStateValidator()}
                onBlur={() =>
                  handleFetchPerson(participante.godparent.document, participante.godparent.document_type, "godparent")
                }
                value={participante.godparent.document_type ?? ""}
                onChange={(e: any, value: any) => {
                  setParticipante(prev => ({
                    ...prev,
                    godparent: {
                      ...prev.godparent,
                      document_type: value.id,
                    }
                  }));
                }}
                size={Sizes.SMALL}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id={`${DataPersonal.DOCUMENT}_godparent`}
                label={DatosPersonales.NUMBER_DOCUMENT}
                type={TEXT}
                disabled={disabledGodparent}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{ borderRadius: "5px" }}
                value={participante.godparent.document ?? ""}
                onBlur={(e) =>
                  handleFetchPerson(participante.godparent.document, participante.godparent.document_type, "godparent")
                }
                onChange={(e) =>
                  handleFormChange("godparent.document", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={`${DataPersonal.BIRTHDATE}_godparent`}
                label={DatosPersonales.FECHA_NACIMIENTO}
                type={DATE}
                disabled={disabledGodparent}
                value={participante.godparent.birthdate ?? ""}
                InputLabelProps={{ sx: inputLabelStyles }}
                style={{ borderRadius: "5px" }}
                onChange={(e) =>
                  handleFormChange("godparent.birthdate", e.target.value)
                }
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.EMAIL}
                label={DatosPersonales.EMAIL}
                type={TEXT}
                autoComplete={State.OFF}
                value={participante.godparent.email || ""}
                onChange={(e) => handleFormChange("godparent.email", e.target.value)}
                margin={Sizes.NORMAL}
                size={Sizes.SMALL}
                disabled={disabledGodparent}
                fullWidth
                error={false}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id={DataPersonal.PHONE}
                label={DatosPersonales.TELEFONO}
                type={DataPersonal.NUMBER}
                autoComplete={State.OFF}
                value={participante.godparent.phone || ""}
                onChange={(e) => handleFormChange("godparent.phone", e.target.value)}
                margin={Sizes.NORMAL}
                disabled={disabledGodparent}
                size={Sizes.SMALL}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid sx={stylesActividadParroquial.gridButton}>
            {!isEditRoute && (
              <Button
                type={FormTextSend.SUBMIT}
                variant={CreateActividadParroquialEnum.CONTAINED_BUTTON}
                color={CreateActividadParroquialEnum.PRIMARY}
                style={stylesActividadParroquial.buttonSave}
              >
                {CreateActividadParroquialEnum.GUARDAR}
              </Button>
            )}
          </Grid>
        </form>
      </Box>
    </Box >
  );
};

export default CreateParticipante;