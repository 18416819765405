import React from "react";
import { Box, IconButton, TableCell, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../../components/genericTable/GenericTable";
import { convertUTCToLocal, getOnlyDate } from "../../../utils/datesUtils/dateUtils";
import NavLinkAdministratorGroups from "../createActividadParroquial/LinksAdministrationGroup";
import { ActividadParroquialRoutes, ActividadParroquialTextos, CreateActividadParroquialEnum } from "../../../enums/pages/CreateActividadParroquialEnum";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { stylesListadoActividadParroquial } from "./ListadoActividadesParroquialesStyles";
import turnoExpecificationService from "../../../services/turnosExpecification";
import moment from "moment";
import { formatCriteryFeriados, formatPeriodicity } from "../../../utils/pages/TurnoUtils";

const ListadoDeActividadesParroquialesPendientes = () => {
    const navigate = useNavigate();
    const [forceRefresh, setForceRefresh] = React.useState(new Date());
    const [totalTurnos, setTotalTurnos] = React.useState(0);
    const actividadParroquialColumns = [
        {
            name: "Fecha de inicio / fin",
            accessor: "startTime",
            customReder: (row: any, column: any) => {
                const date = new Date(row.startTime);
                return (
                    <TableCell>
                        {row.startTime && row.endTimeRepetition ? `${moment(getOnlyDate(row.startTime)).format("DD-MM-YYYY")} / ${moment(getOnlyDate(row.endTimeRepetition)).format("DD-MM-YYYY")}` : '-'}
                    </TableCell>
                );
            },
        },
        {
            name: "Hora de Inicio / Fin",
            accessor: "",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.startHour && row.endHour ? `${convertUTCToLocal(row.startHour)} / ${convertUTCToLocal(row.endHour)}` : '-'}
                    </TableCell>
                );
            },
        },
        {
            name: "Periodicidad",
            accessor: "",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {formatPeriodicity(row.periodicity)}
                    </TableCell>
                );
            },
        },
        {
            name: "Semana", accessor: "week",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell> {row.week ? `${row.week}` : '-'} </TableCell>
                );
            },
        },
        {
            name: "Día", accessor: "day",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell> {row.day ? `${row.day}` : '-'} </TableCell>
                );
            },
        },
        {
            name: "Días Habilitados",
            accessor: "",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.enabledDays.length > 0 ? row.enabledDays.join(', ') : '-'}
                    </TableCell>
                );
            },
        },
        {
            name: "Criterio Feriados", accessor: "",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {formatCriteryFeriados(row.criteryFeriados)}
                    </TableCell>
                );
            },
        },
        {
            name: "Sector",
            accessor: "sector.description",
            customReder: (row: any, column: any) => {
                return (
                    <TableCell>
                        {row.sector ? row.sector.description : '-'}
                    </TableCell>
                );
            },
        },
    ];

    /**
   * Actions for actividad parroquial
   */
    const actividadParroquialActions = [
        {
            icon: (row: any) => <VisibilityIcon />,
            onClick: (row: any) => {
                navigate(`/panel/actividad-parroquial/listado-encuentros/${row.id}`, {
                });
            },
        },
    ];

    const fetchActividadesParroquialesPendientes = async (
        page: number,
        pageSize: number,
        filter?: string,
    ) => {
        const response = await turnoExpecificationService.getSpecificationActividadesParroquiales(page, pageSize);
        const result = response?.data;
        const total = response?.total;
        setTotalTurnos(total);

        return result;
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Box
            sx={stylesListadoActividadParroquial.box1}
        >
            <Box
                sx={stylesListadoActividadParroquial.box2}
            >
                <Box sx={stylesListadoActividadParroquial.box3}>
                    <IconButton onClick={handleGoBack} sx={stylesListadoActividadParroquial.iconButton}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography style={stylesListadoActividadParroquial.typography}>
                        {CreateActividadParroquialEnum.CATEDRAL_DE_LA_PLATA}
                    </Typography>
                </Box>

                <Box sx={stylesListadoActividadParroquial.containerLinks}>
                    <Typography variant="subtitle1" sx={stylesListadoActividadParroquial.title}>
                        {ActividadParroquialTextos.TituloAdministracionGrupo}
                    </Typography>

                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.AltaActividad}
                        label={ActividadParroquialTextos.AltaActividad}
                        isActiveStyle={stylesListadoActividadParroquial.activeLink}
                        defaultStyle={stylesListadoActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        label={ActividadParroquialTextos.ProgramarEncuentro}
                        isActiveStyle={stylesListadoActividadParroquial.activeLink}
                        defaultStyle={stylesListadoActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.ListadoEncuentros}
                        label={ActividadParroquialTextos.ListadoEncuentros}
                        isActiveStyle={stylesListadoActividadParroquial.activeLink}
                        defaultStyle={stylesListadoActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.ListadoIntegrantes}
                        label={ActividadParroquialTextos.ListadoIntegrantes}
                        isActiveStyle={stylesListadoActividadParroquial.activeLink}
                        defaultStyle={stylesListadoActividadParroquial.italicLink}
                    />
                    <NavLinkAdministratorGroups
                        to={ActividadParroquialRoutes.AltaParticipantes}
                        label={ActividadParroquialTextos.AltaParticipantes}
                        isActiveStyle={stylesListadoActividadParroquial.activeLink}
                        defaultStyle={stylesListadoActividadParroquial.italicLink}
                    />
                </Box>
                <div></div>
            </Box>
            <Box
                sx={stylesListadoActividadParroquial.boxPrincipalListado}
            >
                <div
                    style={stylesListadoActividadParroquial.divListado}
                ></div>
                <GenericTable<any>
                    filtersData={[{ param: "status", value: "pending" }]}
                    refresh={forceRefresh}
                    columns={actividadParroquialColumns}
                    actions={actividadParroquialActions}
                    title="turnos"
                    fetchData={fetchActividadesParroquialesPendientes}
                    totalTurnos={totalTurnos}
                    filters={() => {
                        return <></>;
                    }}
                />
            </Box>
        </Box>
    );
};

export default ListadoDeActividadesParroquialesPendientes;
