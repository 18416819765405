import axios, { AxiosResponse } from "axios";
import { GenericService } from "../GenericService";

export class ParticipanteActividadParroquialService extends GenericService {
    constructor() {
        super();
    }

    async createParticipante(data: any): Promise<any> {
        try {
            const response: AxiosResponse<any> = await this.post(
                "/participanteActividadParroquial/create",
                data,
            );
            return response;
        } catch (error) {
            throw new Error("Error al crear el participante de la actividad parroquial");
        }
    }
  
    async getParticipantesByTurnoExpecificationId(id: number): Promise<any> {
      try {
        const response: AxiosResponse<any> = await this.get(
          `/participanteActividadParroquial/getparticipanteActividadParroquialByTurnoExpecificationId/${id}`
        );
        return response;
      } catch (error) {
        throw new Error("Error al obtener los participantes de la actividad parroquial");
      }
    }
  
    async getParticipantesById(id: number): Promise<any> {
      try {
        const response: AxiosResponse<any> = await this.get(
          `/participanteActividadParroquial/getParticipantesWithRelation/${id}`
        );
        return response;
      } catch (error) {
        throw new Error("Error al obtener los participantes de la actividad parroquial");
      }
    }

}

const participanteActividadParroquialService = new ParticipanteActividadParroquialService();

export default participanteActividadParroquialService;
