import { id } from "date-fns/locale";

export const assignResponseToParticipante = (formData: any, response: any, typePeople: string, bautismoSacramento: any = null) => {
    const updatedFormData = { ...formData }

    const filterResponse = (target: any, source: any) => {
        return Object.keys(target).reduce((acc, key) => {
            if (source.hasOwnProperty(key)) {
                acc[key] = source[key];
            }
            return acc;
        }, {} as any);
    };

    switch (typePeople) {
        case 'ninio':
            updatedFormData.ninio = {
                ...updatedFormData.ninio,
                ...filterResponse(updatedFormData.ninio, response),
                birthLocality: response.locality ?? null,
            };
            if (response.father) {
                updatedFormData.father = {
                    ...updatedFormData.father,
                    ...filterResponse(updatedFormData.father, response.father),
                };
            }
            if (response.mother) {
                updatedFormData.mother = {
                    ...updatedFormData.mother,
                    ...filterResponse(updatedFormData.mother, response.mother),
                };
            }
            if (bautismoSacramento && bautismoSacramento.sacramento && bautismoSacramento.sacramento.iglesia) {
                updatedFormData.iglesia = {
                    ...updatedFormData.iglesia,
                    ...bautismoSacramento.sacramento.iglesia,
                };
            }
            break;
        case 'father':
            updatedFormData.father = {
                ...updatedFormData.father,
                ...filterResponse(updatedFormData.father, response),
            };
            break;
        case 'mother':
            updatedFormData.mother = {
                ...updatedFormData.mother,
                ...filterResponse(updatedFormData.mother, response),
            };
            break;
        case 'godparent':
            updatedFormData.godparent = {
                ...updatedFormData.godparent,
                ...filterResponse(updatedFormData.godparent, response),
            };
            break;
        default:
            throw new Error(`Unknown typePeople: ${typePeople}`);
    }

    return updatedFormData;
};

export const optionsYears = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
];

export const initialParticipanteState = () => ({
    type: '',
    activity: {
        nameId: 0,
        groupId: 0,
        turnoExpecificationId: 0,
    },
    school: {
        schoolId: null,
        localityId: null,
        name: '',
        yearStudying: '',
        cue: '',
        religious: false,
    },
    ninio: {
        id: null,
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        birthLocality: null,
        dateSacramento: '',
    },
    father: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    mother: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    godparent: {
        name: '',
        surname: '',
        document_type: '',
        document: '',
        birthdate: '',
        email: '',
        phone: '',
    },
    iglesia: {
        idIgle: null,
        book: '',
        folio: '',
        dateBaptism: '',
    }
});

//TODO fetch colegios and cueColegios
export const colegiosMocked = [
    { id: 1, name: 'Colegio 1' },
    { id: 2, name: 'Colegio 2' },
    { id: 3, name: 'Colegio 3' },
];

export const cueColegiosMocked = [
    { id: 1, name: 'CUE 1' },
    { id: 2, name: 'CUE 2' },
    { id: 3, name: 'CUE 3' },
];

export interface HeaderPropsForActivityParroquial {
    handleGoBack: () => void;
  }
/**
 * Sorts an array of objects by a specific property.
 * @param array - The array of objects to sort.
 * @param property - The property by which to sort.
 * @returns The sorted array.
 */
export const sortByProperty = (array: any[], property: any): any[] => {
    return array.sort((a, b) => {
      const propA = a[property];
      const propB = b[property];
  
      if (typeof propA === 'string' && typeof propB === 'string') {
        return propA.localeCompare(propB);
      }
  
      return 0;
    });
  };