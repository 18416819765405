export enum RoutesEnum {
  HOME = "/",
  LOGIN = "/login",
  REGISTER = "/register",
  START = "/start",
  PERSON_FORM = "/person-form",
  //TODO check this
  PANEL = "/panel/",
  CALENDAR = "/panel/calendario/",
  CALENDAR_V2 = "/panel/calendar",
  BAUTISMO = "/panel/calendario/bautismo",
  CERTIFICACION_BAUTISMO = "/panel/certificados/bautismo",
  CERTIFICACION_CONFIRMACION = "/panel/certificados/confirmacion",
  CERTIFICACION_CASAMIENTO = "/panel/certificados/casamiento",
  CERTIFICACION_COMUNION = "/panel/certificados/comunion",
  ROLES = "/panel/mi-cuenta/roles",
  ROLES_IGLESIA = "/panel/mi-cuenta/roles-iglesia/:id",
  ADD_ROLE_IGLESIA = "/panel/mi-cuenta/add-role-iglesia/:id",
  CONFIRMACIONES = "/panel/calendario/confirmaciones",
  TURNOS = "/panel/turnos",
  TURNOS_CREATE = "/panel/turnos/create",
  TURNOS_EDIT = "/panel/turnos/edit/:id",
  TURNOS_CONFIGURATION = "/panel/turnos/configuracion",
  FERIADOS = "/panel/feriados",
  FERIADOS_CREATE = "/panel/feriados/create",
  FERIADOS_EDIT = "/panel/feriados/edit/:id",
  TURNOS_CONFIGURATION_CREATE = "/panel/turnos/configuracion-create",
  TURNOS_PENDIENTES = "/panel/turnos/pendientes",
  MISAS_PROGRAMADAS = "/panel/misas/programadas",
  LISTADO_MISAS = "/panel/misas/list/:id",
  EVENT_LIST = "/panel/event-list/:id",
  CASAMIENTOS = "/panel/calendario/casamientos",
  COMUNIONES = "/panel/calendario/comuniones",
  CONFIGURATION = "/panel/mi-cuenta/configuracion",
  ADMIN_USERS = "/panel/admin-users",
  ADMIN_USERS_ITEM = "/panel/admin-users/:document",
  CERTIFICATIONS = "/certifications",
  RESET_PASSWORD = "/reset-password",
  SET_PASSWORD = "/set-password",
  SECTORES = "/configuracion/sectores",
  IGLESIA_CONFIGURATION = "/configuracion/iglesia",
  PROFILE = "/panel/perfil",
  SECTORES_CREATE = "/configuracion/sectores/create",
  SECTORES_EDIT = "/configuracion/sectores/edit/:id",
  PERSONAS = "/panel/personas",
  PERSONAS_DATA = "/panel/personas/:id",
  PERSONAS_DATA_FATHER = "/panel/personas/:id/padre/:fatherId",
  PERSONAS_DATA_MOTHER = "/panel/personas/:id/madre/:motherId",
  SACERDOTE_SHADOW_LIST = "/panel/crear-sacerdotes-user",
  SACERDOTE_SHADOW_CREATE = "/panel/crear-sacerdotes-user/:id",
  ACTIVIDAD_PARROQUIAL = "/panel/actividad-parroquial",
  ACTIVIDAD_PARROQUIAL_CREATE = "/panel/actividad-parroquial/create",
  ACTIVIDAD_PARROQUIAL_CREATE_EVENT = "/panel/actividad-parroquial/create-event",
  ACTIVIDADES_PARROQUIALES_PENDIENTES = "/panel/actividad-parroquial/listado-encuentros",
  ACTIVIDAD_PARROQUIAL_EDIT = "/panel/actividad-parroquial/edit/:id",
  LISTADO_ACTIVIDADES_PARROQUIALES = "/panel/actividad-parroquial/listado-encuentros/:id",
  LISTADO_PARTICIPANTES_ACTIVIDAD_PARROQUIAL = "/panel/actividad-parroquial/listado-integrantes",
  CREATE_PARTICIPANTES_ACTIVIDAD_PARROQUIAL = "/panel/actividad-parroquial/create-participantes",
  EDIT_PARTICIPANTES_ACTIVIDAD_PARROQUIAL = "/panel/actividad-parroquial/edit-participantes/:document/:documentType",
}
